// @flow
import { memo } from 'react';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { palette } from '@dt/theme';
import { Text } from '@dt/material-components';

type Props = {
  logs: Array<string>,
};

const useStyles = makeStyles({
  confirmBox: {
    padding: 8,
    paddingLeft: 24,
    margin: 0,
    borderRadius: 4,
    background: palette.gray50,
  },
  tagBox: {
    padding: 8,
    borderRadius: 4,
    background: palette.gray45,
    maxWidth: 200,
  },
});

const AssetTagsEditDialogLogs = ({ logs }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Text component={'div'} variant={'body'}>
          Summary of changes
        </Text>
      </Grid>
      <Grid item xs={12}>
        <Box component={'ul'} className={classes.confirmBox}>
          {logs.map((log, idx) => (
            <Text key={idx} component={'li'} variant={'body'}>
              {log}
            </Text>
          ))}
        </Box>
      </Grid>
    </Grid>
  );
};

export default memo<Props>(AssetTagsEditDialogLogs);
