// @flow
import { memo } from 'react';
import { Card, Grid, Avatar, Tooltip, Typography } from '@mui/material';
import { palette } from '@dt/theme';
import { ExtLink } from '@dt/material-components';
import DatatheoremLogo from '@dt/brand/logo.png';
import GavelIcon from '@mui/icons-material/Gavel';
import { makeStyles } from '@mui/styles';
import type { PolicyDecorated } from '@dt/horizon-api';
import type { Policy } from '@dt/graphql-support/types';
import Skeleton from '@mui/material/Skeleton';

const useStyles = makeStyles({
  assetCard: ({ gutterBottom }) => ({
    marginBottom: gutterBottom ? 24 : 'unset',
    padding: 16,
  }),
  avatar: { background: palette.brand35 },
  contentContainer: {},
  violationTagStrip: {
    display: 'flex',
  },
  urlWrap: {
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
  },
});

type Props =
  | {
      isLoading: true,
    }
  | {
      isLoading?: false,
      productBasePath?: '/api' | '/web' | '/cloud' | '/mobile-secure',
      policy: PolicyDecorated | Policy,
      gutterBottom?: boolean,
    };

/*
 * @deprecated - Use `ahura/src/components/policy/PolicyCard` instead.
 */
const PolicyCard = (props: Props) => {
  const classes = useStyles(props);

  const card = (
    <Card className={classes.assetCard}>
      <Grid container direction="row" alignItems="center" wrap="nowrap" spacing={2}>
        {/* Left */}
        <Grid item xs={6}>
          <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
            {/* Avatar */}
            <Grid item>
              {props.isLoading ? (
                <Skeleton animation="wave" variant="circular" height={40} width={40} />
              ) : (
                <Avatar style={{ backgroundColor: palette.gray45 }}>
                  <GavelIcon style={{ color: palette.gray20 }} />
                </Avatar>
              )}
            </Grid>

            {/* Title & Description */}
            <Grid item>
              <Grid container direction="column" spacing={1}>
                <Grid item>
                  {props.isLoading ? (
                    <Skeleton animation="wave" width={100} />
                  ) : (
                    <Typography variant="body1" component="div" className={classes.urlWrap}>
                      {props.policy.name}{' '}
                      {props.policy.is_special_dt_policy && (
                        <Tooltip
                          disableInteractive
                          title="This policy has been crafted by Data Theorem's security experts."
                        >
                          <img
                            style={{
                              width: 18,
                              height: 18,
                              verticalAlign: 'middle',
                              objectFit: 'cover',
                            }}
                            src={DatatheoremLogo}
                          />
                        </Tooltip>
                      )}
                    </Typography>
                  )}
                </Grid>

                <Grid item>
                  {props.isLoading ? (
                    <Skeleton animation="wave" width={170} />
                  ) : (
                    <Typography variant="body2">
                      Created by{' '}
                      {props.policy.created_by_user &&
                        `${props.policy.created_by_user.first_name} ${props.policy.created_by_user.last_name}`}{' '}
                      on {new Date(props.policy.date_created).toLocaleDateString()}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* Right */}
        <Grid item xs={6}>
          <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
            {/* Stats */}
            <Grid item>
              <Grid container direction="column" spacing={1} style={{ textAlign: 'right' }}>
                <Grid item>
                  {props.isLoading ? (
                    <Skeleton animation="wave" width={120} />
                  ) : (
                    <Typography variant="body2">{props.policy.enabled_policy_rules_count} rules enabled</Typography>
                  )}
                </Grid>

                <Grid item>
                  {props.isLoading ? (
                    <Skeleton animation="wave" width={160} />
                  ) : (
                    <Typography variant="body2">
                      {props.policy.protected_asset_groups_count} asset group
                      {props.policy.protected_asset_groups_count !== 1 ? 's' : ''} protected
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
  return props.isLoading || !props.productBasePath ? (
    card
  ) : (
    <ExtLink target="_self" to={`${props.productBasePath}/policies/${props.policy.id}`}>
      {card}
    </ExtLink>
  );
};

export default memo<Props>(PolicyCard);
