// @flow
import { memo } from 'react';
import { Button } from '@mui/material';

type Props = {
  disabled: boolean,
  onClick: () => void,
};

const AssetTagsEditDialogAddTag = ({ disabled, onClick }: Props) => {
  return (
    <Button variant="contained" disabled={disabled} onClick={onClick}>
      Add Tag
    </Button>
  );
};

export default memo<Props>(AssetTagsEditDialogAddTag);
