// @flow
import { memo } from 'react';
import { palette } from '@dt/theme';
import Box from '@mui/material/Box';
import { Grid, Tooltip, SvgIcon } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  label: {
    width: 'auto',
    padding: '0 6px',
    borderRadius: 2,
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    backgroundColor: palette.gray45,
    color: palette.gray30,
  },
  icon: {
    marginRight: 2,
    padding: 2,
  },
  text: {
    padding: '4px 2px',
  },
});

type Props = { name: string, Icon?: typeof SvgIcon, count: number | 'Unknown' };

function CountAsset({ name, count, Icon }: Props) {
  const classes = useStyles();

  return (
    <Box component={Grid} item>
      <Tooltip disableInteractive title={`${count} ${name}`} aria-label={`${count} ${name}`}>
        <Box className={classes.label}>
          {Icon ? <Icon fontSize={'small'} className={classes.icon} /> : null}
          <span className={classes.text}>{`${count} ${name}`}</span>
        </Box>
      </Tooltip>
    </Box>
  );
}

export default memo<Props>(CountAsset);
