var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @ts-ignore: @reach/router types
import { Link, useLocation } from '@reach/router';
import { Button } from '@mui/material';
import NavigationItemContent from './NavigationItemContent';
var NavigationItem = function (_a) {
    var baseUrl = _a.baseUrl, label = _a.label, to = _a.to, Icon = _a.Icon, _b = _a.isSvg, isSvg = _b === void 0 ? false : _b, _c = _a.isButton, isButton = _c === void 0 ? false : _c, _d = _a.disabled, disabled = _d === void 0 ? false : _d, _e = _a.tooltip, tooltip = _e === void 0 ? false : _e, onClick = _a.onClick;
    var location = useLocation();
    var isCurrent = false;
    if (baseUrl && location.pathname.indexOf(baseUrl) > -1) {
        var condition = true;
        if (baseUrl.includes('devsecops') && location.pathname.includes('stanford-dish-security-university')) {
            condition = baseUrl.includes('stanford-dish-security-university');
        }
        isCurrent = condition;
    }
    if (isButton) {
        return (_jsx(Button, __assign({ style: {
                background: 'none',
                border: 'none',
                padding: 0,
                margin: 0,
                cursor: 'pointer',
                minWidth: 0,
            }, disabled: disabled, onClick: onClick, "aria-label": 'navigation-menu' }, { children: _jsx(NavigationItemContent, { isCurrent: isCurrent, label: label, Icon: Icon, isSvg: isSvg, tooltip: tooltip }) })));
    }
    if (disabled) {
        return (_jsx(NavigationItemContent, { isCurrent: isCurrent, label: label, Icon: Icon, isSvg: isSvg, disabled: true, tooltip: tooltip }));
    }
    return (_jsx(Link, __assign({ to: disabled ? '' : to }, { children: _jsx(NavigationItemContent, { isCurrent: isCurrent, label: label, Icon: Icon, isSvg: isSvg, tooltip: tooltip }) })));
};
export default NavigationItem;
