var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
// @ts-ignore: #TODO-IMPLEMENT-REACT-ROUTER
import { Link, useLocation } from '@reach/router';
import { Snackbar, Button } from '@mui/material';
import { Alert, AlertTitle } from '@mui/material';
import { makeStyles } from '@mui/styles';
var LAUNCHED_AREAS = ['api', 'mobile-secure', 'web', 'cloud'];
var useStyles = makeStyles({
    actions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        columnGap: '4px',
        marginBlockStart: '8px',
        marginInline: '-8px',
    },
});
var V2Banner = function () {
    var _a = useState(true), open = _a[0], setOpen = _a[1];
    var pathname = useLocation().pathname;
    var classes = useStyles();
    var start = pathname
        .split('/')
        .filter(Boolean)
        .shift();
    if (pathname.includes('v2') || !LAUNCHED_AREAS.includes(start) || (pathname === null || pathname === void 0 ? void 0 : pathname.includes('/share')))
        return null;
    var redirectLink;
    switch (start) {
        case 'api':
            redirectLink = '/api/v2';
            break;
        case 'web':
            redirectLink = '/web/v2';
            break;
        case 'cloud':
            redirectLink = '/cloud/v2';
            break;
        case 'mobile':
        case 'mobile-secure':
            redirectLink = '/mobile-secure/v2';
    }
    var handleClose = function () {
        setOpen(false);
    };
    return (_jsx(Snackbar, __assign({ open: open, onClose: handleClose, anchorOrigin: { vertical: 'top', horizontal: 'center' } }, { children: _jsxs(Alert, __assign({ onClose: handleClose, severity: "info", action: _jsx("div", { style: { display: 'none' } }) }, { children: [_jsx(AlertTitle, { children: "The Data Theorem AppSec portal has been updated" }), "Select \u201CAwesome\u201D to be redirected now, or select \u201CWait\u201D to stay on the previous version.", _jsxs("div", __assign({ className: classes.actions }, { children: [_jsx(Button, __assign({ color: "primary", onClick: handleClose }, { children: "Wait" })), _jsx(Button, __assign({ color: "primary", component: Link, to: redirectLink }, { children: "Awesome" }))] }))] })) })));
};
export default V2Banner;
