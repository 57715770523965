// @flow
import { memo } from 'react';
import { Grid, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { palette } from '@dt/theme';

let useStyles = makeStyles({
  divider: props => ({
    height: 2,
    width: '20%',
    backgroundColor: props.color || palette.blue20,
    marginTop: 12,
    marginBottom: 12,
  }),
});

type Props = {
  color: string,
};

function ToolkitDivider({ color }: Props) {
  const classes = useStyles({ color });
  return (
    <Grid container>
      <Grid item xs={12}>
        <Divider className={classes.divider} />
      </Grid>
    </Grid>
  );
}

export default memo<Props>(ToolkitDivider);
