var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import InfoIcon from '@mui/icons-material/Info';
import Text from '@dt/ahura/src/components/Text';
// @ts-ignore: #TODO-IMPLEMENT-TYPES-THEME
import { palette } from '@dt/theme';
var productMapping = {
    api: 'API',
    web: 'Web',
    mobile: 'Mobile',
    cloud: 'Cloud',
    openscan: 'Supply Chain Security',
    dashboard: 'Dashboard',
};
var NavigationNoAccess = function (_a) {
    var product = _a.product;
    return (_jsxs("div", __assign({ style: {
            display: 'flex',
            alignItems: 'center',
            padding: 16,
            justifyContent: 'center',
            backgroundColor: palette.blue50,
        } }, { children: [_jsx(InfoIcon, { style: { marginRight: 16, color: palette.blue10 } }), _jsx(Text, __assign({ variant: "titleXS", component: "div" }, { children: "".concat(productMapping[product], " Secure is not currently licensed. Please contact") })), _jsx("a", __assign({ href: "mailto:support@datatheorem.com" }, { children: _jsx(Text, __assign({ variant: "titleXS", component: "div", style: { color: 'rgb(0,0,238)' } }, { children: "\u00A0support@datatheorem.com\u00A0" })) })), _jsx(Text, __assign({ variant: "titleXS", component: "div" }, { children: "for temporary access and/or live demo of your results." }))] })));
};
export default NavigationNoAccess;
