// @flow
import type { Node } from 'react';

import { memo } from 'react';
import Text from './Text';
import { Grid, Card, CardContent } from '@mui/material';

type Props = {
  heading: string,
  children: Node,
};

function CardWithHeading({ heading, children }: Props) {
  return (
    <Grid container direction="row" spacing={1}>
      <Grid item xs={12}>
        <Text variant="titleXS" style={{ margin: 0, textTransform: 'uppercase' }}>
          {heading}
        </Text>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardContent>{children}</CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default memo<Props>(CardWithHeading);
