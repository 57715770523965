// @flow
import type { Node } from 'react';

import { memo, useState } from 'react';
import { Grid, Popper, Paper, ClickAwayListener } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  popover: {
    padding: 8,
    borderRadius: 4,
    margin: 8,
  },
}));

type Props = {
  container: Node,
  width?: string | number,
  height?: string | number,
  children: Node,
};

export default memo<Props>(({ container, width, height, children }: Props) => {
  const classes = useStyles();

  // popover
  const [anchorForPopOver, setAnchorForPopOver] = useState(null);
  const handleOpenPopperToggle = event => {
    setAnchorForPopOver(anchorForPopOver ? null : event.currentTarget);
  };
  const isPopOverOpen = Boolean(anchorForPopOver);
  const assetPopperId = isPopOverOpen ? `asset-popper` : null;

  return (
    <>
      <Grid container justifyContent="center" onClick={handleOpenPopperToggle}>
        {children}
      </Grid>
      {/* popover container */}
      <Popper id={assetPopperId} open={isPopOverOpen} anchorEl={anchorForPopOver} placement={'bottom-start'}>
        <ClickAwayListener onClickAway={handleOpenPopperToggle}>
          <Paper
            className={classes.popover}
            style={{
              height: height ? height : 'initial',
              width: width ? width : 'initial',
            }}
          >
            {container}
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
});
