//@flow
import Skeleton from '@mui/material/Skeleton';
import { palette } from '@dt/theme';
import { makeStyles } from '@mui/styles';

const commonSkeleton = {
  backgroundColor: palette.brand35,
  opacity: '40%',
};

const useStyles = makeStyles({
  headerItem: {
    ...commonSkeleton,
    marginTop: 16,
    marginBottom: 16,
    marginLeft: 16,
    marginRight: 16,
  },
  topItem: {
    ...commonSkeleton,
    margin: 16,
    marginRight: 8 * 8,
  },
  footer: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
  },
  bottomItem: {
    ...commonSkeleton,
    margin: 16,
    marginRight: 64,
  },
});

/*
 * Loading state for the `ConfigurableLHSMenu`.
 */
const ConfigurableLHSMenuSkeleton = function ConfigurableLHSMenuSkeleton() {
  const classes = useStyles();

  return (
    <>
      {/* Header */}
      <Skeleton animation="wave" variant="rectangular" height={40} className={classes.headerItem} />

      {/* Top Menu Items */}
      <Skeleton animation="wave" variant="rectangular" height={25} className={classes.topItem} />
      <Skeleton animation="wave" variant="rectangular" height={25} className={classes.topItem} />
      <Skeleton animation="wave" variant="rectangular" height={25} className={classes.topItem} />
      <Skeleton animation="wave" variant="rectangular" height={25} className={classes.topItem} />
      <Skeleton animation="wave" variant="rectangular" height={25} className={classes.topItem} />
      <Skeleton animation="wave" variant="rectangular" height={25} className={classes.topItem} />

      {/* Bottom Menu Items */}
      <div className={classes.footer}>
        <Skeleton animation="wave" variant="rectangular" height={25} className={classes.bottomItem} />
        <Skeleton animation="wave" variant="rectangular" height={25} className={classes.bottomItem} />
        <Skeleton animation="wave" variant="rectangular" height={25} className={classes.bottomItem} />
        <Skeleton animation="wave" variant="rectangular" height={25} className={classes.bottomItem} />
      </div>
    </>
  );
};

export default ConfigurableLHSMenuSkeleton;
