// @flow
import { Link } from 'react-router-dom';

// Components@
import SearchIcon from '@mui/icons-material/Search';
import { Button } from '@mui/material';
import { palette } from '@dt/theme';

const searchButtonStyle = {
  lineHeight: 'normal',
  height: '30px',
  color: palette.gray40,
  marginRight: '8px',
  marginBottom: '3px',
};

const SearchButton = () => (
  <Link to="/issues/search">
    <div style={searchButtonStyle}>
      <Button color="inherit">
        {' '}
        <SearchIcon />
        &nbsp;&nbsp;Search
      </Button>
    </div>
  </Link>
);

export default SearchButton;
