//@flow
import { PureComponent } from 'react';
import Button from '@mui/material/Button';

export default class PreviewButton extends PureComponent<{
  onPreviewClick: (e: SyntheticMouseEvent<HTMLElement>) => void,
}> {
  render() {
    return (
      <Button color="primary" variant="outlined" aria-label="Preview" onClick={this.props.onPreviewClick}>
        Preview
      </Button>
    );
  }
}
