// @flow
import { Fragment, memo, useState } from 'react';
import { NavItem } from '../index';
import { Menu, MenuItem } from '@mui/material';
import NavLink from '../NavLink';
import { palette } from '@dt/theme';
import type { DTProductConfig, LeftMenuTopLevelEntry } from './ConfigurableLHSMenuPage';
import MobileNavLink from '../MobileNavLink';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  navGroup: {
    '&:hover': {
      backgroundColor: palette.brand20,
    },
  },
});
type Props = {
  product: DTProductConfig,
  menuItems?: $ReadOnlyArray<LeftMenuTopLevelEntry>,
  // Mobile requires react-router-dom instead of @reach/router
  isUsingMobileRouting: boolean,
  drawerOpen: boolean,
  showHeader: boolean,
};

function ConfigurableLHSMenuRoutes({ product, menuItems, drawerOpen, showHeader, isUsingMobileRouting }: Props) {
  const [currentIdx, setCurrentIdx] = useState(null);
  const [switcherAnchorEl, setSwitcherAnchorEl] = useState(null);
  const handleOpenMenuSwitcher = (e, idx) => {
    setCurrentIdx(idx);
    setSwitcherAnchorEl(e.currentTarget);
  };
  const handleCloseMenuSwitcher = () => {
    setCurrentIdx(null);
    setSwitcherAnchorEl(null);
  };
  const styles = useStyles();
  const menuOpen = Boolean(switcherAnchorEl);
  const leftMenu = [];
  if (menuItems) {
    leftMenu.push(...menuItems);
  }

  if (!showHeader) {
    leftMenu.unshift(product);
  }

  return (
    <>
      <div style={{ marginTop: '15px' }}></div>
      {leftMenu.map((entry, idx) => {
        return (
          <Fragment key={entry.name}>
            <div className={styles.navGroup}>
              {/* Top-level menu entry */}
              <NavItem
                key={idx}
                to={entry.to}
                target={entry.target || '_blank'}
                exact={entry?.exact ? entry.exact : false}
                text={entry.name}
                redirect={entry?.redirect}
                drawerOpen={drawerOpen}
                isUsingMobileRouting={isUsingMobileRouting}
              >
                {drawerOpen && <entry.icon />}

                {!drawerOpen && (
                  // $FlowFixMe
                  <entry.icon
                    onClick={e => handleOpenMenuSwitcher(e, idx)}
                    aria-label={`${entry.name}`}
                    aria-controls={`sub-menu-${idx}`}
                    aria-haspopup={'true'}
                  />
                )}

                {/* Sub-level menu collapsed drawer entries */}
                {!drawerOpen && entry.subEntries && entry.subEntries.length > 0 && (
                  <Menu
                    id={`sub-menu-${idx}`}
                    keepMounted
                    open={menuOpen && currentIdx === idx}
                    anchorEl={switcherAnchorEl}
                    onClose={handleCloseMenuSwitcher}
                    getContentAnchorEl={null}
                    anchorReference="anchorEl"
                    anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
                  >
                    {idx === currentIdx &&
                      entry.subEntries.map((subEntry, subIdx) =>
                        isUsingMobileRouting ? (
                          <MobileNavLink
                            key={subIdx}
                            to={subEntry.to}
                            target={subEntry.target || '_blank'}
                            redirect={subEntry?.redirect}
                            onClick={handleCloseMenuSwitcher}
                            activeStyle={{ backgroundColor: palette.brand35 }}
                          >
                            <MenuItem>{subEntry.name}</MenuItem>
                          </MobileNavLink>
                        ) : (
                          <NavLink
                            key={subIdx}
                            target={subEntry.target || '_blank'}
                            to={subEntry.to}
                            redirect={subEntry?.redirect}
                            onClick={handleCloseMenuSwitcher}
                            activeStyle={{ backgroundColor: palette.brand35 }}
                          >
                            <MenuItem>{subEntry.name}</MenuItem>
                          </NavLink>
                        ),
                      )}
                  </Menu>
                )}
              </NavItem>

              {/* Sub-level menu expanded drawer entries */}
              {drawerOpen &&
                entry.subEntries &&
                entry.subEntries.map((subEntry, subIdx) => {
                  return (
                    <NavItem
                      key={subIdx}
                      target={subEntry.target || '_blank'}
                      to={subEntry.to}
                      exact={subEntry?.exact ? entry.exact : false}
                      text={subEntry.name}
                      redirect={subEntry?.redirect}
                      drawerOpen={drawerOpen}
                      isUsingMobileRouting={isUsingMobileRouting}
                    >
                      <hr width="1" size="50" />
                    </NavItem>
                  );
                })}
            </div>
          </Fragment>
        );
      })}
    </>
  );
}

export default memo<Props>(ConfigurableLHSMenuRoutes);
