// @flow
import type { Node } from 'react';

import { memo } from 'react';
import Drawer from '@mui/material/Drawer';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';

let useStyles = makeStyles({
  content: {
    width: '60%',
    minWidth: '200px',
  },
});

type Props = {
  open: boolean,
  onClose: () => void,
  children: Node,
};

function DrawerRight({ open, onClose, children }: Props) {
  let css = useStyles();
  return (
    <Drawer anchor="right" open={open} onClose={onClose} classes={{ paper: css.content }}>
      <Box padding="16px" height="100%">
        {children}
      </Box>
    </Drawer>
  );
}

export default memo<Props>(DrawerRight);
