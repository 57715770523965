var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
// @ts-ignore TODO-IMPLEMENT-TYPES
import horizon_notifications from '@dt/graphql-support/horizon/notification_events';
// @ts-ignore TODO-IMPLEMENT-TYPES
import sevenhell_notifications from '@dt/graphql-support/sevenhell/portal_notifications';
// @ts-ignore TODO-IMPLEMENT-APOLLO-LINK-SCHEMA-REST
import { useQuery } from '@dt/apollo-link-schema-rest';
import { Drawer, Grid } from '@mui/material';
import SlackLogo from '@dt/material-components/svg/slack_logo.svg';
import Text from '@dt/ahura/src/components/Text';
import { NotificationItem, NotificationItemZeroState, NotificationItemLoadingSkeleton, NotificationItemError,
// @ts-ignore: #TODO-IMPLEMENT-TYPES-COMPONENTS
 } from '@dt/material-components/notifications';
// @ts-ignore: #TODO-IMPLEMENT-TYPES-THEME
import { palette } from '@dt/theme';
import differenceInDays from 'date-fns/difference_in_days';
import { Waypoint } from 'react-waypoint';
import format from 'date-fns/format';
var NotificationsDrawer = function (_a) {
    var _b, _c, _d, _e, _f, _g;
    var isOpen = _a.isOpen, toggle = _a.toggle;
    var _h = useQuery(horizon_notifications.list), horizonNotificationsData = _h.data, horizonNotificationsLoading = _h.loading, horizonNotificationsError = _h.error, horizonNotificationsFetchMore = _h.fetchMore;
    var _j = useQuery(sevenhell_notifications.list), sevenhellNotificationsData = _j.data, sevenhellNotificationsLoading = _j.loading, sevenhellNotificationsError = _j.error, sevenhellNotificationsFetchMore = _j.fetchMore;
    var horizonNotifications = ((_b = horizonNotificationsData === null || horizonNotificationsData === void 0 ? void 0 : horizonNotificationsData.notification_events_list) === null || _b === void 0 ? void 0 : _b.events)
        ? horizonNotificationsData.notification_events_list.events
        : [];
    var sevenhellNotifications = ((_c = sevenhellNotificationsData === null || sevenhellNotificationsData === void 0 ? void 0 : sevenhellNotificationsData.portal_notifications_list) === null || _c === void 0 ? void 0 : _c.portal_notifications)
        ? sevenhellNotificationsData.portal_notifications_list.portal_notifications
        : [];
    var notificationsLoading = sevenhellNotificationsLoading || horizonNotificationsLoading;
    var notificationsError = sevenhellNotificationsError || horizonNotificationsError;
    var currentDate = new Date(Date.now());
    var hasTodayBeenChecked = false;
    if (horizonNotificationsError) {
        console.error('horizonNotificationsError:', horizonNotificationsError);
    }
    if (sevenhellNotificationsError) {
        console.error('sevenhellNotificationsError:', sevenhellNotificationsError);
    }
    var notifications = useMemo(function () {
        return __spreadArray(__spreadArray([], horizonNotifications, true), sevenhellNotifications, true).sort(function (a, b) {
            if (a.date_created && b.date_created) {
                if (a.date_created > b.date_created) {
                    return -1;
                }
                return 1;
            }
            return 0;
        });
    }, [horizonNotifications, sevenhellNotifications]);
    return (_jsx(Drawer, __assign({ open: isOpen, onClose: toggle, anchor: "right", style: { zIndex: 1100 }, PaperProps: {
            style: {
                width: 488 + 72,
                overflowX: 'hidden',
            },
        }, "aria-labelledby": 'Notifications Drawer' }, { children: _jsxs(Grid, __assign({ container: true, direction: "column", spacing: 2, alignItems: "flex-start", style: { padding: 16 } }, { children: [_jsx(Grid, __assign({ item: true }, { children: _jsxs("div", __assign({ style: { margin: '8px 0', display: 'flex', alignItems: 'center' } }, { children: [_jsx(Text, __assign({ variant: "titleS", style: { marginRight: 4 }, component: "span" }, { children: "Alerts" })), _jsxs(Text, __assign({ variant: "body", component: "span" }, { children: ["(contact ", _jsx("a", __assign({ href: "mailto:support@datatheorem.com" }, { children: "support@datatheorem.com" })), " to join the Slack Channel"] })), _jsx("img", { src: SlackLogo, height: 18, width: 18, alt: "Slack Icon", style: { marginLeft: 4, marginRight: 4 } }), _jsx(Text, __assign({ variant: "body", component: "span" }, { children: ")" }))] })) })), !notifications && !notificationsLoading && !notificationsError && _jsx(NotificationItemZeroState, {}), notifications
                    ? notifications.map(function (notification) {
                        var dateHeader = null;
                        if (differenceInDays(Date.now(), notification.date_created) === 0 && !hasTodayBeenChecked) {
                            dateHeader = 'Today';
                            hasTodayBeenChecked = true;
                        }
                        if (differenceInDays(currentDate, notification.date_created) > 0) {
                            dateHeader = format(notification.date_created, 'MMM DD YYYY');
                            currentDate = notification.date_created;
                        }
                        return (_jsxs(Grid, __assign({ item: true }, { children: [Boolean(dateHeader) && (_jsx(Text, __assign({ variant: "titleXS", style: { margin: 0, color: palette.gray35 } }, { children: dateHeader }))), _jsx(NotificationItem, { notification: notification })] }), notification.id));
                    })
                    : null, notificationsLoading &&
                    [0, 1, 2, 3, 4, 5, 6].map(function (i) {
                        return (_jsx(Grid, __assign({ item: true }, { children: _jsx(NotificationItemLoadingSkeleton, {}) }), i));
                    }), !sevenhellNotificationsLoading &&
                    ((_e = (_d = sevenhellNotificationsData === null || sevenhellNotificationsData === void 0 ? void 0 : sevenhellNotificationsData.portal_notifications_list) === null || _d === void 0 ? void 0 : _d.pagination_information) === null || _e === void 0 ? void 0 : _e.next_cursor) && (_jsx(Grid, __assign({ item: true }, { children: _jsx(Waypoint, { onEnter: function () {
                            sevenhellNotificationsFetchMore && sevenhellNotificationsFetchMore();
                        } }) }))), !horizonNotificationsLoading &&
                    ((_g = (_f = horizonNotificationsData === null || horizonNotificationsData === void 0 ? void 0 : horizonNotificationsData.notification_events_list) === null || _f === void 0 ? void 0 : _f.pagination_information) === null || _g === void 0 ? void 0 : _g.next_cursor) && (_jsx(Grid, __assign({ item: true }, { children: _jsx(Waypoint, { onEnter: function () {
                            horizonNotificationsFetchMore && horizonNotificationsFetchMore();
                        } }) }))), (horizonNotificationsError || sevenhellNotificationsError) && _jsx(NotificationItemError, {})] })) })));
};
export default NotificationsDrawer;
