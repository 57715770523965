//@flow
import { memo } from 'react';

import { getInvariantUserAccount, clearSessionAndRedirectToLogin } from '@dt/session';
import UserRole from '@dt/enums/UserRoleEnum';

import NewReleaseIcon from '@mui/icons-material/NewReleases';
import UsersIcon from '@mui/icons-material/AccountCircle';
import HelpIcon from '@mui/icons-material/HelpOutline';
import AlertsIcon from '@mui/icons-material/Notifications';
import LogoutIcon from '@mui/icons-material/ExitToAppOutlined';
import ShareIcon from '@mui/icons-material/Share';
import ActivityIcon from '@mui/icons-material/History';
import PollIcon from '@mui/icons-material/Poll';
import PersonIcon from '@mui/icons-material/Person';

import { Menu, MenuItem, ListItemIcon } from '@mui/material';

import ExtLink from './ExtLink';

type Props = {
  anchorEl: HTMLElement | null,
  handleClose: () => void,
};

const GlobalSettingsMenu = (props: Props) => {
  const { anchorEl, handleClose } = props;
  const { currentUser } = getInvariantUserAccount();

  return (
    <Menu
      disableScrollLock
      id="settings-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      getContentAnchorEl={null}
      anchorReference="anchorEl"
      anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
    >
      <ExtLink
        to="/mobile/sdlc/stanford-dish-security-university"
        target="_self"
        onClick={handleClose}
        aria-label="What's New Menu Item"
      >
        <MenuItem>
          <ListItemIcon>
            <NewReleaseIcon />
          </ListItemIcon>
          What&apos;s New
        </MenuItem>
      </ExtLink>

      {currentUser.role === UserRole.MANAGER && (
        <ExtLink to="/management/activity" target="_self" onClick={handleClose} aria-label="Activity Menu Item">
          <MenuItem>
            <ListItemIcon>
              <ActivityIcon />
            </ListItemIcon>
            Activity
          </MenuItem>
        </ExtLink>
      )}

      <ExtLink
        to={currentUser.role === 'MANAGER' ? '/management/users/' : '/management/users/invite'}
        target="_self"
        onClick={handleClose}
        aria-label="Users Menu Item"
      >
        <MenuItem disabled={!currentUser.can_invite_users}>
          <ListItemIcon>
            <UsersIcon />
          </ListItemIcon>
          {currentUser.role === 'MANAGER' ? 'Manage ' : 'Invite '}Users
        </MenuItem>
      </ExtLink>

      <ExtLink to="/mobile/preferences" target="_self" onClick={handleClose} aria-label="Preferences Menu Item">
        <MenuItem>
          <ListItemIcon>
            <AlertsIcon />
          </ListItemIcon>
          Alerts
        </MenuItem>
      </ExtLink>

      <ExtLink to="/mobile/shared-links" target="_self" onClick={handleClose} aria-label="Shared Links Menu Item">
        <MenuItem>
          <ListItemIcon>
            <ShareIcon />
          </ListItemIcon>
          Shared Links
        </MenuItem>
      </ExtLink>

      <ExtLink
        to="/management/compliance-report"
        target="_self"
        onClick={handleClose}
        aria-label="Compliance Report Menu Item"
      >
        <MenuItem>
          <ListItemIcon>
            <PollIcon />
          </ListItemIcon>
          Compliance
        </MenuItem>
      </ExtLink>

      <ExtLink to="/management/profile" target="_self" onClick={handleClose} aria-label="Profile Menu Item">
        <MenuItem>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          Profile
        </MenuItem>
      </ExtLink>

      <ExtLink
        to="https://support.securetheorem.com/"
        target="_blank"
        onClick={handleClose}
        aria-label="Help and Support Menu Item"
      >
        <MenuItem>
          <ListItemIcon>
            <HelpIcon />
          </ListItemIcon>
          Help &amp; Support
        </MenuItem>
      </ExtLink>

      <MenuItem
        onClick={() => {
          clearSessionAndRedirectToLogin();
        }}
        aria-label="Sign Out Menu Item"
      >
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        Sign out
      </MenuItem>
    </Menu>
  );
};

export default memo<Props>(GlobalSettingsMenu);
