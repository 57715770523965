// @flow
import { memo } from 'react';
import { Button, Grid } from '@mui/material';
import Box from '@mui/material/Box';

type Props = {
  confirm: () => void,
  cancel: () => void,
  disabled: boolean,
};

const AssetTagsEditDialogConfirmRemove = ({ confirm, disabled, cancel }) => {
  return (
    <Grid container justifyContent={'flex-start'}>
      <Box>
        <Button color="primary" variant="contained" disabled={disabled} onClick={confirm}>
          Save
        </Button>
      </Box>
      <Box ml={1}>
        <Button variant="contained" onClick={cancel}>
          Cancel
        </Button>
      </Box>
    </Grid>
  );
};

export default memo<Props>(AssetTagsEditDialogConfirmRemove);
