// @flow
import { memo, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import ErrorIcon from '@mui/icons-material/Error';
import { Tag, Text } from '@dt/material-components';
import assets from '@dt/graphql-support/horizon/assets';
import AssetTagsEditDialog from './AssetTagsEditDialog';
import type { AssetTagsQuery, AssetTagsQueryVariables } from '@dt/graphql-support/types';
import { palette } from '@dt/theme';
import useGetHorizonAssetIdFromAppId from '@dt/material-components/hooks/use_get_horizon_asset_id_from_app_id';

type Props =
  | {
      assetId: string,
      assetName: string,
    }
  | {
      appId: string,
      appName: string,
    };

const AssetTagList = (props: Props) => {
  const [isAssetTagsEditDialogOpen, setAssetTagsEditDialogOpen] = useState(false);

  const [horizonId, setHorizonId] = useState(props.assetId || null);

  const mobileApp = useGetHorizonAssetIdFromAppId(props?.appId || null);

  useEffect(() => {
    if (mobileApp?.id) {
      setHorizonId(mobileApp?.id);
    }
  }, [mobileApp]);

  const [assetTagQuery, { data, loading, error }] = useLazyQuery<AssetTagsQuery, AssetTagsQueryVariables>(
    assets.asset_tags.get,
  );

  useEffect(() => {
    if (horizonId) {
      assetTagQuery({
        variables: {
          uuid: horizonId,
        },
      });
    }
  }, [assetTagQuery, props, horizonId]);

  if (loading || mobileApp?.loading) {
    return (
      <Box display={'flex'}>
        <Box ml={1}>
          <Skeleton variant="text" animation="wave" height={40} width={80} />
        </Box>
        <Box ml={1}>
          <Skeleton variant="text" animation="wave" height={40} width={80} />
        </Box>
        <Box ml={1}>
          <Skeleton variant="text" animation="wave" height={40} width={80} />
        </Box>
      </Box>
    );
  }

  if (mobileApp?.error) {
    const error = mobileApp?.error;
    return (
      <Tag
        icon={<ErrorIcon />}
        backgroundColor={palette.red30}
        color={palette.white}
        label={error.message.replace('GraphQL error: ', '')}
      />
    );
  }

  if (error) {
    return (
      <Tag
        icon={<ErrorIcon />}
        backgroundColor={palette.red30}
        color={palette.white}
        label={error.message.replace('GraphQL error: ', '')}
      />
    );
  }

  if (!props.assetId && !mobileApp?.id) {
    return <Tag icon={<ErrorIcon />} backgroundColor={palette.red30} color={palette.white} label={'Unknown Error'} />;
  }

  return (
    <>
      <Box display={'flex'} flexWrap={'wrap'}>
        {data?.asset_tags?.asset_tags?.map(assetTag => (
          <Box key={assetTag.id} style={{ cursor: 'pointer' }} onClick={() => setAssetTagsEditDialogOpen(true)}>
            <Tag label={`${assetTag.tag}${assetTag.value ? ':' + assetTag.value : ''}`} maxWidth={100} />
          </Box>
        ))}
        {!data?.asset_tags?.asset_tags?.length ? (
          <Box style={{ cursor: 'pointer' }} onClick={() => setAssetTagsEditDialogOpen(true)}>
            <Text variant={'link'} component={'div'}>
              Add New
            </Text>
          </Box>
        ) : null}
      </Box>
      {mobileApp?.id && props.appName ? (
        <AssetTagsEditDialog
          isOpen={isAssetTagsEditDialogOpen}
          onClose={() => setAssetTagsEditDialogOpen(false)}
          assetName={props.appName}
          assetId={mobileApp?.id}
        />
      ) : null}
      {props.assetId && props.assetName ? (
        <AssetTagsEditDialog
          isOpen={isAssetTagsEditDialogOpen}
          onClose={() => setAssetTagsEditDialogOpen(false)}
          assetName={props.assetName}
          assetId={props.assetId}
        />
      ) : null}
    </>
  );
};

export default memo<Props>(AssetTagList);
