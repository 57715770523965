// @flow

import { memo } from 'react';

import Box from '@mui/material/Box';
import formatDate from 'date-fns/format';
import { makeStyles } from '@mui/styles';
import { palette } from '@dt/theme';

type Props = {
  lastScanStatus: string,
  lastScanDateCreated: string | Date,
  discoveredDate: Date,
};

const useStyles = makeStyles({
  discovered: {
    padding: '2px 4px',
    borderRadius: 2,
    fontSize: 14,
    backgroundColor: palette.yellow40,
    color: palette.gray30,
  },
  lastTest: {
    padding: '2px 4px',
    borderRadius: 2,
    fontSize: 14,
    backgroundColor: palette.gray50,
    color: palette.gray30,
  },
});

const ToolkitAssetTableLastTested = ({ lastScanStatus, lastScanDateCreated, discoveredDate }: Props) => {
  const classes = useStyles();

  return (
    <Box display="flex" justifyContent="center">
      {lastScanStatus === 'no-scan' ? (
        <Box container className={classes.discovered} justifyContent="center">
          New - Discovered on {formatDate(discoveredDate, 'MMM D, YYYY')}
        </Box>
      ) : (
        <Box className={classes.lastTest} justifyContent="center">
          Last tested on {formatDate(lastScanDateCreated, 'MMM D, YYYY')}
        </Box>
      )}
    </Box>
  );
};

export default memo<Props>(ToolkitAssetTableLastTested);
