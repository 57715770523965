var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import Text from '@dt/ahura/src/components/Text';
// @ts-ignore: #TODO-IMPLEMENT-TYPES-THEME
import { palette } from '@dt/theme';
import { SvgIcon, Tooltip } from '@mui/material';
var currentRouteStyle = function (isCurrent, disabled) { return ({
    borderRadius: 12,
    backgroundColor: disabled ? '#ccc' : isCurrent ? palette.white : 'initial',
    color: disabled ? '#666' : isCurrent ? palette.brand30 : palette.gray30,
    fill: isCurrent ? palette.brand30 : palette.gray30,
    padding: '8px 12px',
    margin: 0,
    height: 38,
}); };
var NavigationItemContent = function (_a) {
    var Icon = _a.Icon, isCurrent = _a.isCurrent, label = _a.label, isSvg = _a.isSvg, _b = _a.disabled, disabled = _b === void 0 ? false : _b, _c = _a.tooltip, tooltip = _c === void 0 ? false : _c;
    return (_jsx(_Fragment, { children: _jsx(Tooltip, __assign({ disableInteractive: true, title: tooltip ? label : '' }, { children: !Icon ? (_jsx(Text, __assign({ variant: "titleS", style: currentRouteStyle(isCurrent, disabled) }, { children: label }))) : (_jsx(Box, { children: isSvg ? (_jsx("div", __assign({ style: currentRouteStyle(isCurrent, disabled) }, { children: _jsx(SvgIcon, { children: Icon }) }))) : (_jsx(Box, __assign({ style: currentRouteStyle(isCurrent, disabled) }, { children: _jsx(Icon, {}) }))) })) })) }));
};
export default NavigationItemContent;
