// @flow
import type { Node } from 'react';

import { memo } from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  label: {
    position: 'relative',
    top: '-60%',
    textAlign: 'center',
    height: 0,
  },
});

type Props = {
  total: {
    count: number,
    color: string,
  },
  completed: {
    count: number,
    color: string,
  },
  children: Node,
  strokeWidth?: number,
  size?: number,
};

const CircularChartComponent = function CircularChart({ total, completed, strokeWidth, size, children }: Props) {
  const classes = useStyles();

  return (
    <Grid container justifyContent={'center'}>
      <Grid item xs={12} style={{ maxHeight: size || 200 }}>
        <svg width="100%" height="100%" viewBox="0 0 42 42">
          <circle
            cx="21"
            cy="21"
            r="15.91549430918954"
            fill="transparent"
            stroke={`${total.color}`}
            strokeWidth={strokeWidth || 7}
            aria-label={`total-${total.count}-${completed.color}`}
          ></circle>
          <circle
            cx="21"
            cy="21"
            r="15.91549430918954"
            fill="transparent"
            stroke={`${completed.color}`}
            strokeWidth={strokeWidth || 7}
            strokeDasharray={`${completed.count} 100 ${total.count}`}
            aria-label={`completed-${total.count}-${completed.color}`}
          ></circle>
        </svg>
      </Grid>
      <Grid item xs={12} className={classes.label}>
        {children}
      </Grid>
    </Grid>
  );
};

export default memo<Props>(CircularChartComponent);
